import axios from 'axios';
import { useEffect, useState } from 'react'
import GetConfig from '../../config/getConfig';
import ApplicationForms from './applicationforms';
import Blog from './blog';
import ContactForms from './contactforms';
import LocaleInputs from './localeinputs';
import './panel.css'

export default function Panel(credentials) {
    const [page, setpage] = useState(0);
    const [locale, setlocale] = useState();
    const [loading, setloading] = useState(true);

    function panelPage() {
        switch (page) {
            case 0:
                return (
                    <div className='localeeditor'>
                        <h1>Content Editor</h1>
                        <LocaleInputs setlocale={setlocale} locale={locale} credentials={credentials} />
                    </div>
                )
            case 1:
                return (
                    <div>
                        <h1>Blog Editor</h1>
                        <Blog credentials={credentials} />
                    </div>
                )
            case 2:
                return (
                    <div>
                        <h1>Contact Forms</h1>
                        <ContactForms credentials={credentials} />
                    </div>
                )
            case 3:
                return (
                    <div>
                        <h1>Application Forms</h1>
                        <ApplicationForms credentials={credentials} />
                    </div>
                )
            default:
                break;
        }
    }
    useEffect(() => {
        async function getLocale() {
            setloading(true)
            await axios.get(GetConfig().api + "/locale/")
                .then((res) => {
                    if (res.status === 200) {
                        setlocale(res.data)
                        setloading(false)
                    } else {
                        credentials.setlogin()
                        setloading(false)
                    }
                })
                .catch((err) => {
                    credentials.setlogin()
                    setloading(false)
                })
        }
        getLocale();
    }, []);
    return (
        <div className="panel">
            <div className="panel-nav">
                {
                    [
                        'Content Editor',
                        'Blog',
                        'Contact Forms',
                        'Applications'
                    ].map((e, i) => {
                        return (
                            <p onClick={() => { setpage(i) }} key={"a" + i}>{e}</p>
                        )
                    })
                }
            </div>
            <div className="panel-page">

                {loading ? <p>Loading</p> : <div className='inner-panel'>{panelPage()}</div>}
            </div>
        </div>
    )
}