import axios from "axios";
import { useEffect, useState } from "react"
import GetConfig from "../../config/getConfig";

export default function ApplicationForms({credentials}) {
    const [loading, setloading] = useState(true);
    const [forms, setforms] = useState();

    useEffect(() => {
        async function getForms() {
            setloading(true)

            await axios.get(GetConfig().api + '/postform', {
                headers: credentials.login
                })
                .then((res) => {
                    setforms(res.data)
                })
                .catch((err) => {
                    console.log(err)
                })
            setloading(false)

        }
        getForms();

    }, []);

    if (!loading) {
        return (
            <div className="contact-forms">
                {
                    forms.map((e, i) => {
                        return (
                            <div key={"formc" + i} className="contact-form">
                                <p><span>Name:</span> {e.name}</p>
                                <p><span>Email:</span> {e.email}</p>
                                <p><span>Hear:</span> {e.hear}</p>
                                <p><span>File:</span> <a href={'https://' + String(e.fileurl).split('/home/hanymany/')[1]}>{String(e.fileurl).split('/home/hanymany/')[1]}</a></p>
                                <p><span>Question 1</span>: {e.question1}</p>
                                <p><span>Question 2</span>: {e.question2}</p>
                                <p><span>Tarih </span>: {e.createdAt? new Date(e.createdAt).toLocaleDateString("tr-TR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) : 'Veri Yok'}</p>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}