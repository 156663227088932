import "./footer.css"
import "./footer_fhd.css"
import "./footer_4k.css"
import "./footer_mobile.css"

export default function Footer() {
    return (
        <footer className="footer">
            <div className="footer-logo">
                <img src="assets/logo-gray.svg" alt="hanymany-logo" />
                <p>hanypany</p>
            </div>
           
            <div className="footer-bottom">
                <p>hanypany</p>
                <div className="footer-lines flex">
                    <div></div>
                    <div></div>
                </div>
            </div>
        </footer>
    )
}