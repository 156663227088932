import './header.css'
import './header_mobile.css'
import './header_fhd.css'
import './header_fhd.css'

import { Link, useLocation } from "react-router-dom";
import { useState } from 'react';

export default function Header() {

    const { pathname } = useLocation();

    const [Nav, setNav] = useState(false);

    return (
        <header className="header">
            <div className="header-inner">
                <div className="header-logo">
                    <img src="assets/logo.svg" alt="hanymany-logo" />
                    <p>hanypany</p>
                </div>
                {/* <div className="header-switch" onClick={() => { setNav(!Nav) }}>
                    <i className="fa-solid fa-bars fa-2x"></i>
                </div>
                <div className={`header-nav ${Nav ? "active-nav" : ''}`}>
                    {[
                        {
                            text: locale[0],
                            path: "/"
                        },
                        {
                            text: locale[1],
                            path: "/about"
                        },
                        {
                            text: locale[2],
                            path: "/apply"
                        },
                        {
                            text: locale[3],
                            path: "/blog"
                        },
                    ].map((elt) => {
                        return (
                            <div key={elt.text} className={`nav-el ${pathname === elt.path ? "active" : ""}`}>
                                <Link onClick={() => { setNav(!Nav) }} to={elt.path}>{elt.text}</Link>
                            </div>
                        )
                    })
                    }
                    <div className="nav-el" >
                        <i className="fa-solid fa-xmark fa-3x" onClick={() => { setNav(!Nav) }}></i>
                    </div>
                </div>
                <div className="lang">
                    <div className="lang-box" onClick={() => { setLanguage('tr') }}>
                        <p>{language.charAt(0).toUpperCase() + language.slice(1)}</p>
                        <img src={`assets/${language}.png`} alt="flag" className="lang-icon" />
                        <i className="fa-solid fa-chevron-down"></i>
                    </div>
                </div> */}
            </div>
        </header>
    )
}
