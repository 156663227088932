import axios from 'axios'
import { useState } from 'react'
import GetConfig from '../../config/getConfig';
import './login.css'

export default function Login({ setlogin }) {
    const [credentials, setcredentials] = useState({
        username: "",
        password: ""
    });
    const [error, seterror] = useState();
    function inpChange(e) {
        setcredentials({
            ...credentials,
            [e.target.name]: e.target.value
        })
    }
    async function LogIn() {
        const url = GetConfig()
        await axios.post(url.api + '/login', credentials)
        .then((res) => {
            if(res.status === 200 && res.data === "success") {
                seterror()
                setlogin(credentials)
            } else {
                seterror('error')
            }
        })
        .catch((err) => {
            seterror(err.toString())
        });
        
    }
    return (
        <div className="login-page">
            <div className="login-input">
                <input onChange={(e) => {inpChange(e)}} value={credentials.username} className='f700' type="text" name="username" placeholder="Username" />
                <input onChange={(e) => {inpChange(e)}} value={credentials.password} className='f700' type="password" name="password" id="password" placeholder="Password" />
                <button onClick={() => { LogIn() }} className='f700'>Log in</button>
                <div className="error-box">
                    {error? error : ""}
                </div>
            </div>
        </div>
    )
}