import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/template-components/footer/footer";
import Header from "./components/template-components/header/header";
import Login from "./pages/login/login";
import Panel from "./pages/panel/panel";


function App() {

  const [login, setlogin] = useState();
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route exact path="/" element={login ? <Panel login={login} setlogin={setlogin} /> : <Login setlogin={setlogin} />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

