import axios from "axios"
import GetConfig from "../../config/getConfig"

export default function LocaleInputs({ setlocale, locale, credentials }) {
    function inputChange(e, element, element2, element3) {
        setlocale({
            ...locale,
            [element]: {
                ...locale[element],
                [element2]: {
                    ...locale[element][element2],
                    [element3]: e.target.value
                }
            }
        })
    }

    async function saveLocale() {
        const postdata = locale
        delete postdata[0]._id
        delete postdata[1]._id
        delete postdata[0].__v
        delete postdata[1].__v

        postdata[0].post = Object.values(postdata[0].post)
        postdata[1].post = Object.values(postdata[1].post)
        postdata[0].header = Object.values(postdata[0].header)
        postdata[1].header = Object.values(postdata[1].header)
        postdata[0].footer = Object.values(postdata[0].footer)
        postdata[1].footer = Object.values(postdata[1].footer)
        postdata[0].landing = Object.values(postdata[0].landing)
        postdata[1].landing = Object.values(postdata[1].landing)
        postdata[0].about = Object.values(postdata[0].about)
        postdata[1].about = Object.values(postdata[1].about)
        postdata[0].apply = Object.values(postdata[0].apply)
        postdata[1].apply = Object.values(postdata[1].apply)
        postdata[0].blog = Object.values(postdata[0].blog)
        postdata[1].blog = Object.values(postdata[1].blog)

        await axios.post(GetConfig().api + "/locale/update", Object.values(postdata), {
            headers: credentials.login
        })
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    if (locale) {
        return (
            <div>
                <div className="input-flex">
                    {Object.keys(locale).map((element, index) => {
                        return (
                            <div key={element + index} className="input-lang-group">
                                <h3>{locale[element].lang}</h3>
                                <hr />
                                {
                                    Object.keys(locale[element]).map((element2, index2) => {

                                        if ((element2 !== "_id") && (element2 !== "__v") && (element2 !== "lang")) {

                                            return (
                                                <div key={element2 + index2} className="input-group">
                                                    <h4>{element2}</h4>
                                                    <div className="input-group-list">
                                                        {
                                                            Object.keys(locale[element][element2]).map((element3, index3) => {
                                                                return (
                                                                    <input key={element3 + index3} type="text" onChange={(e) => { inputChange(e, element, element2, element3) }} value={locale[element][element2][element3]} />
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        )
                    })
                    }
                </div>
                <div className="save">
                    <button onClick={() => {
                        saveLocale()
                    }}>Save</button>

                </div>
            </div>
        )
    }
}