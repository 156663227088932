import axios from "axios";
import { useEffect, useState } from "react";
import GetConfig from "../../config/getConfig";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import EmailEditor from "react-email-editor";

const getMonthIdx = function (idx) {
    var objDate = new Date();
    objDate.setDate(1);
    objDate.setMonth(idx);

    var locale = "tr",
        month = objDate.toLocaleString(locale, { month: "long" });

    return month;
}


export default function Blog({ credentials }) {
    const navigate = useNavigate();


    const [error, seterror] = useState();
    const [editorLoading, setEditorLoading] = useState(true);
    const [formState, setformState] = useState({
        image: '',
        date: '',
        title: '',
        subtitle: '',
        text: '',
        authorImg: '',
        authorName: '',
        authorPosition: '',
        date: new Date()
    });
    const [formState2, setformState2] = useState({
        _id: '',
        image: '',
        date: '',
        title: '',
        subtitle: '',
        text: '',
        authorImg: '',
        authorName: '',
        authorPosition: '',
        date: new Date()
    });
    const [loading, setloading] = useState(false);
    const [blogPosts, setblogPosts] = useState([]);
    const [modal, setModal] = useState(false);
    const [mailselect, setmailselect] = useState(0);
    const [exported, setExported] = useState();
    const emailEditorRef = useRef(null);

    const onLoad = () => {
        // editor instance is created
        // you can load your template here;
        // const templateJson = {};
        if (!editorLoading) {

        }
    }

    const loadDesign = (design) => {
        emailEditorRef.current.editor.loadDesign(design);
    }
    const onReady = () => {
        console.log('onReady');
        setEditorLoading(false);

    };

    useEffect(() => {
        async function fetchBlogs() {
            setloading(true);
            await axios.get(GetConfig().api + "/blog")
                .then(res => {
                    setblogPosts(res.data);
                })
                .catch(err => {
                    seterror(err);
                })
            setloading(false);
        }


        fetchBlogs();
    }, []);

    function handleInput(e) {
        setformState({
            ...formState,
            [e.target.name]: e.target.value
        })
    }
    function handleInput2(e) {
        setformState2({
            ...formState2,
            [e.target.name]: e.target.value
        })
    }
    function sendForm() {
        emailEditorRef.current.editor.exportHtml((data) => {
            axios.post(GetConfig().api + "/blog", {
                ...formState,
                html: JSON.stringify(data.html),
                design: JSON.stringify(data.design)
            }, {
                headers: credentials.login
            })
                .then(res => {
                    if (res.data === 'Saved Blog') {
                        setblogPosts([...blogPosts, formState])
                    }
                })
        });


    }
    function updateForm(post) {
        axios.post(GetConfig().api + `/blog/update/${formState2._id}`, formState2, {
            headers: credentials.login
        })
            .then(res => {
                if (res.data === 'Updated Blog') {
                    navigate(0);
                }
            })
    }
    function deletePost(post) {
        axios.delete(GetConfig().api + `/blog/${post._id}`, {
            headers: credentials.login
        })
            .then(res => {
                if (res.data === 'deleted') {
                    setblogPosts(blogPosts.filter(item => item._id !== post._id))
                }
            })
    }
    return (
        <div className="blog-section">
            <div className="new-edit">
                <h2>New Blog Post</h2>
                <input value={formState["title"]} onChange={(e) => { handleInput(e) }} type="text" name="title" id="title" placeholder="title" />
                <input value={formState["subtitle"]} onChange={(e) => { handleInput(e) }} type="text" name="subtitle" id="subtitle" placeholder="second title" />
                <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady} />
                <textarea value={formState["text"]} onChange={(e) => { handleInput(e) }} name="text" id="text" placeholder="content" ></textarea>
                <input value={formState["authorName"]} onChange={(e) => { handleInput(e) }} type="text" name="authorName" id="authorName" placeholder="authorName" />
                <input value={formState["authorPosition"]} onChange={(e) => { handleInput(e) }} type="text" name="authorPosition" id="authorPosition" placeholder="authorPosition" />
                <input value={formState["authorImg"]} onChange={(e) => { handleInput(e) }} type="text" name="authorImg" id="authorImg" placeholder="authorImg url" />
                <input value={formState["image"]} onChange={(e) => { handleInput(e) }} type="text" name="image" id="image" placeholder="image url" />
                <input value={formState["date"]} onChange={(e) => { handleInput(e) }} type="date" name="date" id="date" />
                <button onClick={() => { sendForm() }}>Add blog post</button>
            </div>
            <div className={`new-edit edit-blog ${modal ? '' : 'hidden'}`}>
                <h2>Edit Blog Post <i className="fa-solid fa-xmark" onClick={() => { setModal(!modal) }}></i></h2>
                <p>Id: {formState2["_id"]}</p>
                <input value={formState2["title"]} onChange={(e) => { handleInput2(e) }} type="text" name="title" id="title" placeholder="title" />
                <input value={formState2["subtitle"]} onChange={(e) => { handleInput2(e) }} type="text" name="subtitle" id="subtitle" placeholder="second title" />
                <textarea value={formState2["text"]} onChange={(e) => { handleInput2(e) }} name="text" id="text" placeholder="content" rows='5' cols='30'></textarea>
                <input value={formState2["authorName"]} onChange={(e) => { handleInput2(e) }} type="text" name="authorName" id="authorName" placeholder="authorName" />
                <input value={formState2["authorPosition"]} onChange={(e) => { handleInput2(e) }} type="text" name="authorPosition" id="authorPosition" placeholder="authorPosition" />
                <input value={formState2["authorImg"]} onChange={(e) => { handleInput2(e) }} type="text" name="authorImg" id="authorImg" placeholder="authorImg url" />
                <input value={formState2["image"]} onChange={(e) => { handleInput2(e) }} type="text" name="image" id="image" placeholder="image url" />
                <input value={formState2["date"]} onChange={(e) => { handleInput2(e) }} type="date" name="date" id="date" />
                <button onClick={() => { updateForm() }}>Update blog post</button>

            </div>
            <div className="blog-list">
                <h2>Blog Posts</h2>
                <div className="blog-list-container">
                    {loading ? <h1>Loading</h1> : blogPosts.map((post, index) => {
                        return (
                            <div className="blog-post" key={index}>
                                <div className="blog-post-header">
                                    <div className="blog-post-header-text">
                                        <h3>Id: {post._id}</h3>
                                        <h3>Title: {post.title}</h3>
                                        <h4>Subtitle: {post.subtitle}</h4>
                                    </div>
                                    <div className="blog-post-header-img">
                                        <img src={post.image} alt="" />
                                    </div>
                                </div>
                                <div className="blog-post-content">
                                    Content: {post.text.slice(0, 100) + '...'}
                                </div>
                                <div className="blog-post-footer">
                                    <div className="blog-post-footer-author">
                                        <div className="blog-post-footer-author-img">
                                            <img src={post.authorImg} />
                                        </div>
                                        <div className="blog-post-footer-author-text">
                                            <h4>{post.authorName}</h4>
                                            <h5>{post.authorPosition}</h5>
                                        </div>
                                    </div>
                                    <div className="blog-post-footer-date">
                                        <p>{new Date(post.date).getDate()} {getMonthIdx(new Date(post.date).getMonth())} {new Date(post.date).getFullYear()}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="footer-edit">
                                    <button onClick={() => {
                                        setModal(!modal)
                                        setformState2(post)
                                    }}>Edit</button>
                                    <button onClick={() => { deletePost(post) }}>Delete</button>
                                </div>
                            </div>
                        )
                    }
                    )
                    }

                </div>
            </div>
        </div>

    )
}